import React from "react"
import PilotForm from "../components/organisms/PilotForm"

const Preorder = () => {
  return (
    <div>
      <PilotForm />
    </div>
  )
}

export default Preorder

import React, { useState } from "react"
import TextareaAutosize from "react-textarea-autosize"
import P from "../../atoms/P"
import Button from "../../atoms/Button"
import HeaderText from "../../atoms/HeaderText"
import styled from "styled-components"

const X = styled.div`
  position: absolute;
  top: 3%;
  right: 3%;
  background: url("/x-icon.svg");
  background-size: cover;
  width: 30px;
  height: 30px;
  cursor: pointer;
`
const Container = styled.div`
  background: #ffffff;
  padding: 20px;
  height: 100%;
  width: 100%;
  border-top: 20px solid ${props => props.theme.colors.site.secondaryLight};
  position: relative;
  input:not([type="radio"]):not([type="checkbox"]) {
    -webkit-appearance: none;
    border-radius: 0;
  }
  textarea {
    -webkit-appearance: none;
    border-radius: 0;
  }

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    padding: 96px 142px 76px 142px;
  }
`
const FirsInputsRow = styled.div`
  margin: 104px 0 49px 0;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  display: flex;
  align-items: center;
  input::placeholder {
    margin-bottom: 24px;
    color: #afaaad;
    display: flex;
    justify-content: flex-start;
    font-size: 16px;
    font-family: "Graphik Web Medium";
  }
  input {
    height: 34px;
    width: 100%;
    font-size: 16px;
    @media only screen and (min-width: ${props =>
        props.theme.screenBreakpoints.tabletMin}px) {
    }
    //mobile
    @media only screen and (min-width: ${props =>
        props.theme.screenBreakpoints.desktopSmallMin}px) {
      width: 384px;
    }
  }
  //mobile
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    flex-direction: row;
  }
`
const SecondInputsRow = styled.div`
  margin: 48px auto 80px auto;
  align-items: flex-start;

  align-items: center;
  display: flex;
  flex-direction: column;
  textarea::placeholder {
    margin-bottom: 24px;
    color: #afaaad;
    display: flex;
    justify-content: flex-start;
    font-size: 16px;
    font-family: "Graphik Web Medium";
  }
  textarea {
    resize: none;
    width: 100%;
  }
`
const TextInput = styled.input`
  border: none;
  border-bottom: 1px solid ${props => props.theme.colors.site.primaryDark};
  &:first-of-type {
    margin-right: 0;
    margin-bottom: 52px;
    //mobile
    @media only screen and (min-width: ${props =>
        props.theme.screenBreakpoints.tabletMin}px) {
      margin-right: 26px;
      margin-bottom: 0;
    }
  }
`
const TextareaInput = styled(TextareaAutosize)`
  border: none;
  border-bottom: 1px solid ${props => props.theme.colors.site.primaryDark};
`
const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
`

const TextWrapper = styled.div``

const PilotForm = ({ closeMethod }) => {
  /* eslint-disable no-unused-vars */
  const [name, setName] = useState(false)
  const [email, setEmail] = useState(false)
  const [message, setMessage] = useState(false)
  /* eslint-disable no-unused-vars */

  return (
    <Container>
      {" "}
      <X onClick={closeMethod} />
      <form
        name="pilot"
        method="POST"
        action="/healthcare/?success=true"
        data-netlify="true"
      >
        <input type="hidden" name="form-name" value="pilot" />
        <TextWrapper>
          <HeaderText color="text">Start Your Pilot Application</HeaderText>
          <P size={6}>
            Thank you for your interest in our pilot opportunities. Let us know
            a bit more about you below and someone from our team will be in
            touch shortly with next steps.
          </P>
        </TextWrapper>
        <FirsInputsRow>
          <TextInput
            name="name"
            type="text"
            placeholder="Full name*"
            onChange={e => setName(e.target.value)}
            required={true}
          />
          <TextInput
            name="email"
            type="email"
            onChange={e => {
              setEmail(e.target.value)
            }}
            placeholder="Email address*"
            required={true}
          />
        </FirsInputsRow>

        <SecondInputsRow>
          <TextareaInput
            placeholder={
              "Tell us a bit more about your care center and desired use of Norbert*"
            }
            onChange={e => setMessage(e.target.value)}
            type="textarea"
            name="message"
            minRows={3}
            id="message_input"
            required={true}
          />
        </SecondInputsRow>
        <ButtonContainer>
          <Button color="primary" type="submit">
            SUBMIT
          </Button>
        </ButtonContainer>
      </form>
    </Container>
  )
}

export default PilotForm
